<template>
  <div v-if="!loading" class="report-view view scroll">
    <div class="sticky-top bg-extra-light px-3 mx-n3 pb-3 pt-2 d-flex justify-content-between align-items-center bg-extra-light">
      <h3>{{ reportConfig?.name }}</h3>
      <button
          type="button"
          class="btn btn-primary btn-sm"
          @click="saveReport"
      >
        {{ $t('report_view.save') }}
      </button>
    </div>
    <form-configured-content
        v-if="report"
        :contents="reportConfigFields"
        :value="report.data"
        @input="onReportInput"
    />
    <div class="row mt-3">
      <div class="col text-right">
        <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="saveReport"
        >
          {{ $t('report_view.save') }}
        </button>
      </div>
    </div>
  </div>
  <loading-screen v-else/>
</template>

<script>

import dayjs from 'dayjs'
import _cloneDeep from 'lodash/cloneDeep'

import { mapActions, mapState } from 'vuex'

import FormConfiguredContent from '@pixelstein/ps-form/components/PsFormConfiguredContent'
import FormSelect from '@pixelstein/ps-form/components/PsFormSelect'
import GeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal'
import RouterLinkBack from '@/components/RouterLinkBack'
import LoadingScreen from '@/components/LoadingScreen'
import { v1 } from 'uuid'
import formConfig from '@/mixins/form-config.js'
import { traverseFields } from 'paperclip-lib/src/report-configs/ReportConfigUtils'
import { collectDateKeys } from '@/helpers/report-config-helper'
import { getParentWindow } from '@/utils/loginDelegation'

export default {
  name: 'ReportView',
  components: {
    LoadingScreen,
    RouterLinkBack,
    FormConfiguredContent,
    FormSelect,
    GeneralModal,
  },
  mixins: [formConfig],
  props: {
    reportConfigId: { type: String, default: null },
    token: { type: String, default: null },
  },
  data () {
    return {
      loading: true,
      report: {},
      saving: false,
      reportConfig: {},
      customCss: '',
      noRouting: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    reportConfigFields() {
      return this.reportConfig?.fields ?? []
    }
  },
  methods: {
    ...mapActions({
      addReport: 'Api/Reports/once',
      findReportConfig: 'Api/ReportConfigs/shared',
      options: 'Api/Options/index',
    }),
    onReportInput (data) {
      this.$set(this.report, 'data', data)
    },
    async saveReport () {
      if (!this.checkMandatoryFields()) {
        this.$toast.open({
          message: this.$t('report_form.check_mandatory_fields'),
          type: 'error',
          position: this.$config?.TOAST_POSITION ?? 'top',
        })

        return
      }

      const report = {
        ..._cloneDeep(this.report),
        token: this.token,
      }

      const dateKeys = collectDateKeys(this.reportConfig.fields)
      dateKeys.forEach(key => report.data[key] = dayjs(report.data[key]).format(this.$config.API_DATE_TIME_FORMAT))

      try {
        this.loading = true

        this.report = await this.addReport(report)

        this.$toast.open({
          message: this.$t('report_form.save_success'),
          type: 'success',
          position: this.$config?.TOAST_POSITION ?? 'top',
        })

        const parent = getParentWindow()

        if (parent) {
          parent.postMessage({
            action: 'FormSaved'
          }, '*')
        }

        if (!this.noRouting) {
          this.$router.push({ name: 'SharedThanks' })
        }
      } finally {
        this.loading = false
        this.saving = false
      }
    },
    async extractFieldConfig () {
      if (!this.reportConfig?.fields) {
        this.reportConfigFields = []
        return
      }

      const fields = _cloneDeep(this.reportConfig.fields)

      await traverseFields(fields, async field => {
        if (this.mode === 'view') {
          field.options.disabled = true
        }

        if (!field.options?.file_id) {
          return
        }

        const file = await this.getFile({ id: field.options.file_id })

        field.options.file = {
          ...file,
          file: this.resolveApiUrl(file.file),
          thumbnail: this.resolveApiUrl(file.thumbnail),
        }

        if (['svg-radio', 'svg-checkbox'].includes(field.options.type)) {
          field.options.svgData = await (await fetch(field.options.file.file)).text()
        }
      })

      this.reportConfigFields = fields
    },
  },
  async mounted () {
    try {
      const urlParams = new URLSearchParams(location.search)

      this.noRouting = urlParams.has('no-routing')

      await this.extractFieldConfig()

      this.loading = true

      const reportConfig = await this.findReportConfig({ id: this.reportConfigId, token: this.token })

      await traverseFields(reportConfig.fields, async field => {
        if (field.options.file) {
          field.options.file.file = this.resolveApiUrl(field.options.file.file)
          field.options.file.thumbnail = this.resolveApiUrl(field.options.file.thumbnail)
        }

        if (['svg-radio', 'svg-checkbox'].includes(field.options.type)) {
          field.options.svgData = await (await fetch(field.options.file.file)).text()
        }
      })

      this.reportConfig = reportConfig

      const report = {
        data: {},
        files: [],
        user_id: null,
        state: null,
        report_config_id: this.reportConfigId,
        report_config: this.reportConfig,
        frozen_report_config: this.reportConfig,
        local_id: v1(),
      }

      const query = new URLSearchParams(location.search)

      report.data = this.inputKeys
          .map(key => ([key, query.get(key)]))
          .filter(([_, value]) => value !== undefined && value !== null && value !== '')
          .map(([key, value]) => ({ [key]: value }))
          .reduce((accu, obj) => ({ ...accu, ...obj }), {})

      this.report = report

      const options = await this.options({filter: {name: 'custom_form_css'}})

      if (options.length) {
        this.customCss = options[0].value

        const style = document.createElement('style')
        style.innerHTML = this.customCss

        document.querySelector('head')?.appendChild(style)
      }
    } catch (err) {
      if ([404, 403].includes(err.code)) {
        this.$router.push({ name: 'NotAvailable' })

        return
      }

      throw err
    } finally {
      this.loading = false
    }
  },
}
</script>
